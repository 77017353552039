export const environment = {
    production: false,
    name: 'dev',
    version: '1.0.0',
    region: 'us-gov-west-1',
    url: 'https://dev-survey.saffire.org',
    endpoint: 'https://dev-be.safire-ai.com',
    sandboxEnabled: false,
    amplify: {
      Auth: {
        identityPoolId: 'us-gov-west-1:045de7ba-c8f7-4f75-bcf7-8c11ac85d2cb',
        region: 'us-gov-west-1',
        userPoolId: 'us-gov-west-1_qCxXRs6hT',
        userPoolWebClientId: '1lt3dduac3qbhfru5lvl15gmjk',
      },
    },
  };
  