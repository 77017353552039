import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then(
        (m) => {
          return m.AuthModule
        }
      ),
  },
  {
    path: 'docs',
    loadChildren: () =>
      import('./docs/docs.module').then(
        (m) => {
          return m.DocsModule
        }
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then(
        (m) => {
          return m.SettingsModule
        }
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/auth.module').then(
        (m) => {
          return m.AuthModule
        }
      ),
  },
  { path: '', redirectTo: '/home', pathMatch: "full" },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then(
        (m) => {
          return m.HomeModule
        }
      ),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
