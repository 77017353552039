<mat-toolbar class="menu">
    <div class="grid grid-cols-3 gap-4 width-100">
        <div class="width-100 content-center" id="sapphire">
            <!-- <button (click)="drawer.toggle()">
                <mat-icon class="document-icon">menu</mat-icon>
            </button> -->
            <div class="grid grid-cols-2 width-100">
                <div class="flex">
                    <!-- <img class="sapphire-image" src="../../../assets/svgs/sapphire.svg"/> -->
                    <div class="toolbar-item-holder">
                        <button class="toolbar-item" (click)="menuClick()">
                            <span class="material-symbols-outlined menu-icon">
                                menu
                            </span>
                        </button>
                    </div>
                    <div class="toolbar-item-holder">
                        <button class="home-button" (click)="goHome()">
                            <span class="tool-bar-color-text toolbar-item">
                                <!-- <img src="../../../assets/images/menu.jpg" class="img-go"> -->
                                Safire
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- <div class="width-100">taskbar option 2</div>
        <div class="width-100">taskbar option 3</div> -->
      </div>
</mat-toolbar>
