import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { ToolbarComponent } from './features/toolbar/toolbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SapphireComponent } from './shared/sapphire/sapphire.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { LoginComponent } from './auth/login/login.component';
import { StoreModule } from '@ngrx/store';
import {MatSidenavModule} from '@angular/material/sidenav';
import { BurgerMenuComponent } from './features/menu/burger-menu/burger-menu.component';
import { LoggingInterceptor } from './http-interceptor.interceptor'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { NgxLoadingModule } from "ngx-loading";
import { AuthService } from './shared/services/auth-service';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ToolbarComponent,
    SapphireComponent,
    BurgerMenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSelectModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({}),
    MatRadioModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatInputModule,
    MatFormFieldModule,
    StoreModule.forRoot({}, {})
  ],
  exports:[
    ToolbarComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true
    },
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
