import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
  constructor(
    private _router: Router
  ){

  }
  @Output() onMenuClicked = new EventEmitter<any>();

  menuClick(){
    this.onMenuClicked.emit("menuClicked")
  }

  goHome(){
    this._router.navigate(['/'])
  }
}
