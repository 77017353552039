import { Component, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss']
})
export class BurgerMenuComponent {
  @ViewChild('drawer')
  public drawer?: MatDrawer
  
  onMenuClicked(value:any) {
    this.drawer?.toggle()
  }

  closeDrawer(){
    this.drawer?.close()
  }
}
