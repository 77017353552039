import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { Amplify, Auth, AuthConfig } from 'aws-amplify';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LibraryOptions, ResourcesConfig } from '@aws-amplify/core';
import { Amplify } from 'aws-amplify';
import { signIn } from 'aws-amplify/auth';
import { CookieService } from 'ngx-cookie-service';
// import amplifyconfig from '../../../environments/test.env.json';
// import amplifyconfig from './amplifyconfiguration.json';

// Amplify.configure(amplifyconfig);

@Injectable()
export class AuthService {
  public loggedIn: BehaviorSubject<boolean>;
  idToken: string
  refreshToken:string
  constructor(
    private router: Router,
    private cookieService: CookieService
  ) {
    this.setAuthTokens()
    // Amplify.configure({
    //   ResourcesConfig: Auth.environment.cognito,
    // });

    // Amplify.configure(environment.amplify.Auth)
    
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  public login(username: string, password: string) {
    return signIn({username, password})
  }

  public setAuthTokens(){
    this.idToken = this.cookieService.get('uam-id-token')
    this.refreshToken = this.cookieService.get('uam-refresh-token')
    console.log("tokens set", this.idToken)
  }

  public getIdToken(){
    console.log("get the id token", this.idToken)
    return this.idToken
  }

  public getRefreshToken(){
    return this.refreshToken
  }


}
