import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, from, Observable, switchMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './shared/services/auth-service';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  idToken: string
  refreshToken: string
  constructor(private authService: AuthService) {
    this.idToken = authService.getIdToken()
    this.refreshToken = authService.getRefreshToken()
    console.log("this.id", this.idToken)
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // return next.handle(request).pipe(
    //   tap({
    //     next: () => null,
    //     error: (error: HttpErrorResponse) => {
    //       console.log(
    //         'the interceptor has caught an error, process it here',
    //         error
    //       );
    //       return throwError(()=>{error})
    //     },
    //   })
    // );
    const endPoint = environment.endpoint;
    const excludedUrls = [
      `${endPoint}auth/settings`,
    ];
    console.log("check the endpoint", endPoint)
    console.log("here is auth token value", this.idToken)
    console.log("Intercepting")
    let authReq: any;
    if (excludedUrls.some((x) => req.url.includes(x))) {
      authReq = req.clone();
      return next.handle(authReq);
    } else {
      return next.handle(req).pipe(
        switchMap((token)=>{
            const authReq = req.clone({
              setHeaders: {
                Authorization: `Bearer ${this.idToken}`
              }
            });
            console.log("Intercepting", authReq)
            return next.handle(authReq);
        })
      )
    }
  }
}